<template>
  <div>Breeding</div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style></style>
